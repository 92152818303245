import { useRouter } from "next/router"
import { useContext } from "react"
import styled from "styled-components"
import { DashboardContainer } from "../components/dashboard/dashboard-wrapper"
import CustomHead from "../components/head"
import Header from "../components/layout/header"
import Sidebar from "../components/layout/sidebar"
import { PurpleButton } from "../components/ui/buttons"
import { LightParagraph } from "../components/ui/elements"
import { appRoutes } from "../constants/app-routes"
import { UserContext } from "../context/user-context"
import { breakpoint, sizes } from "../styles/global-style"

const Custom404 = () => {
    const router = useRouter()
    const { currentUser } = useContext(UserContext)

    const handleDashboard = () => {
        router.replace(appRoutes.DASHBOARD.route)
    }

    return (
        <>
            <CustomHead title="404 Stránka nenalezena" />
            <Wrapper showSidebar={true}>
                {currentUser && (
                    <>
                        <style>{`.user-icon {margin-left: auto;}`}</style>
                        <Header />
                        <Sidebar setShowLoading={() => {}} />
                    </>
                )}
                <AppContent>
                    <DashboardContainer>
                        <NotFoundWrapper>
                            <Inner>
                                <img src="/assets/icons/smiley-purple.svg" alt="ikona" />
                                <h1>Ups... Tohle asi nehledáš </h1>
                                <LightParagraph marginTop={5}>
                                    Něco se porouchalo. 😐 &nbsp;Mrkni, jestli je odkaz správný.
                                </LightParagraph>
                                <PurpleButton onClick={handleDashboard} marginTop={30}>
                                    RADĚJI JDU CVIČIT
                                </PurpleButton>
                            </Inner>
                        </NotFoundWrapper>
                    </DashboardContainer>
                </AppContent>
            </Wrapper>
        </>
    )
}

export default Custom404

const NotFoundWrapper = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 140px);
`

const Inner = styled.div`
    width: 500px;
    padding: 40px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 70px));
    img {
        margin-bottom: 12px;
    }
    ${breakpoint("1024px")} {
        margin-left: calc((100px + 1rem) / -2);
    }
    ${breakpoint("1100px")} {
        margin-left: calc((172px + 1rem) / -2);
    }
`

const Wrapper = styled.div`
    min-width: ${(props) => (!props.forMobile ? sizes.tablet : sizes.min)};
`

const AppContent = styled.div`
    height: calc(100vh - 140px);
`
