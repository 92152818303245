import styled from "styled-components"
import { fullDashboardBreakpoint } from "../../styles/dashboard"
import { breakpoint, color, radius, sizes } from "../../styles/global-style"

export const DashboardContainer = styled.div`
    ${breakpoint(sizes.desktopS)} {
        padding-left: calc(100px + 1rem);
    }
    ${breakpoint("1100px")} {
        padding-left: calc(172px + 1rem);
    }
    ${breakpoint(fullDashboardBreakpoint)} {
        padding-left: calc(202px + 1rem);
    }
`

export const DashboardBottomBanner = styled.div`
    width: 100%;
    max-width: 100%;
    background: ${color.ghost_white};
    margin-top: 40px;
    margin-bottom: 60px;
    padding: 40px;
    
    ${breakpoint(sizes.desktopS)} {
        border-radius: ${radius.large};
        padding: 30px;
        width: auto;
        margin-right: 20px;
        max-width: 1140px;
        margin-bottom: 1rem;
    }

    ${breakpoint(sizes.desktopS)} {
        margin-left: calc(100px + 1rem);
    }

    ${breakpoint("1100px")} {
        margin-left: calc(172px + 1rem);
    }

    ${breakpoint(fullDashboardBreakpoint)} {
        margin-left: calc(202px + 1rem);
    }
`
